import { FC, useRef, useState, useEffect } from 'react'
import get from 'lodash/get'
import find from 'lodash/find'
import reverse from 'lodash/reverse'
import isNil from 'lodash/isNil'
// import sample from 'lodash/sample'
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check'
import { DotLottiePlayer } from '@dotlottie/react-player'
import '@dotlottie/react-player/dist/index.css'
// import { stringSimilarity } from 'string-similarity-js'

import {
  applyResponse,
  sendUserScreenshot,
  submitReply
} from 'controllers/main'
import HeyGenConnect, {
  IHeyGenConnect
} from 'shared/components/HeyGenConnectSdk'
import AzureConnect from 'shared/components/AzureConnect'
import Recorder, { IRecorder } from 'shared/components/Recorder'
import RecorderDummy from 'shared/components/RecorderDummy'
import PageContainer from 'shared/components/PageContainer'
import EndConversation from 'shared/components/EndConversation'
import ControlPanel, { IControlPanel } from 'shared/components/ControlPanel'
import { captureThumb } from 'shared/components/recorder/getVideoInfo'
import FixPermission from 'shared/components/FixPermission'
import PermissionsHandler from 'shared/components/PermissionsHandler'
import PACKAGE from '../../package.json'
import trim from 'lodash/trim'
import has from 'lodash/has'
import jsLogger from 'js-logger'
import { getBackClient } from 'controllers/back'
import * as Proto from 'shared/types/proto'
import { Phrase } from '@facesignai/api'

const SCREENSHOTS_AMOUNT = 3
const SCREENSHOTS_PERIOD = 3000

type Props = {
  clientSecret: string
  sessionInfo: Proto.SessionStartResponse
  onConversationFinished: () => void
  // handleChunk: (
  //   videoBlob: Blob,
  //   mimeType: string,
  //   role: 'user' | 'avatar'
  // ) => void
  isTest?: boolean
  // setDuration: (v: number) => void
  // steps: InteractionT.StepT[]
  // setSteps: (steps: InteractionT.StepT[]) => void
}

const Conversation: FC<Props> = ({
  clientSecret,
  sessionInfo,
  onConversationFinished,
  // handleChunk,
  isTest = false
  // setDuration,
  // steps,
  // setSteps
}) => {
  const [steps, setSteps] = useState(sessionInfo.steps)
  const startTimeRef = useRef<number>(Date.now())
  const recorderRef = useRef<IRecorder>(null)
  const heyGenRef = useRef<IHeyGenConnect>(null)
  const currentHGLatency = useRef<number>(0)
  const cameraVideoRef = useRef<HTMLVideoElement>(null)
  const isOverRef = useRef(false)
  const [orientation, setOrientation] = useState<'portrait' | 'landscape' | ''>(
    ''
  )
  const [controls, setControls] = useState<Proto.ControlT>({
    camera: false,
    mic: false
  })
  const controlsRef = useRef<Proto.ControlT>({ camera: false, mic: false })
  const [thereWasAnError, setThereWasAnError] = useState<string>()
  const [permissionsError, setPermissionsError] =
    useState<Proto.PermissionErrorType | null>(null)
  const streamRef = useRef<MediaStream | null>(null)
  const [closed, setClosed] = useState<boolean>(false)
  const [permissionsChecked, setPermissionsChecked] = useState<boolean>(false)
  const controlPanelRef = useRef<IControlPanel>(null)
  const screenshotTimer = useRef<number>()
  const [userName, setUserName] = useState<string | null>(null)
  const [checkingPermissions, setCheckingPermissions] = useState(false)
  const [controlPanelVisible, setControlPanelVisible] = useState(false)
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null)
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const avatarIsTalkingRef = useRef(true)
  const userIsTalkingRef = useRef(true)
  const nextQuestionRef = useRef<Phrase | null>(null)
  const userSpeechBufferRef = useRef('')
  const screenshotsSentRef = useRef(0)

  useEffect(() => {
    jsLogger.info('CONVERSATION START')
    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_conversation_started', {
        version: PACKAGE.version
      })
    }
    function handleOrientationChange (event: { matches: any; media: any }) {
      const { matches } = event
      setOrientation(matches ? 'portrait' : 'landscape')
    }

    const mediaQueryPortrait = window.matchMedia('(orientation: portrait)')
    setOrientation(mediaQueryPortrait.matches ? 'portrait' : 'landscape')

    mediaQueryPortrait.addEventListener('change', handleOrientationChange)
    return () => {
      mediaQueryPortrait.removeEventListener('change', handleOrientationChange)
    }
  }, [])

  useEffect(() => {
    if (permissionsChecked) {
      startTimeRef.current = Date.now()
    }
  }, [permissionsChecked])

  useEffect(() => {
    jsLogger.log('useEffect permissionsChecked call')
    const run = async () => {
      if (permissionsChecked && !isTest) {
        jsLogger.log('useEffect permissionsChecked, start screnshots timer', {
          permissionsChecked,
          isTest
        })
        screenshotTimer.current = window.setInterval(
          takeScreenshot,
          SCREENSHOTS_PERIOD
        )
        takeScreenshot()
      }
    }
    run()
    return () => {
      if (screenshotTimer.current) {
        window.clearInterval(screenshotTimer.current)
      }
    }
  }, [permissionsChecked])

  useEffect(() => {
    const stream = streamRef.current as MediaStream
    if (stream) {
      const audioTrack = stream
        .getTracks()
        .find(track => track.kind === 'audio')
      if (audioTrack) {
        audioTrack.enabled = controls.mic
      }
      const videoTrack = stream
        .getTracks()
        .find(track => track.kind === 'video')
      if (videoTrack) {
        videoTrack.enabled = controls.camera
      }
    }
  }, [controls])

  const onHeyGenSessionStarted = () => {
    jsLogger.debug('HeyGenSessionStarted')
    jsLogger.log('camera and mic are enabled, saying initial phrase!!!!!!!')
    const heygenLatency = Date.now() - startTimeRef.current
    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_heygen_started', {
        latency: heygenLatency,
        version: PACKAGE.version
      })
    }
    const lastAvatarPhrase = find(reverse(steps), (s: Phrase) => s.isAvatar)
    const text = lastAvatarPhrase ? lastAvatarPhrase.text : 'hello'
    jsLogger.debug('HeyGenSessionStarted, saying initial phrase', {
      text,
      lastAvatarPhrase
    })
    window.top &&
      window.top.postMessage(
        JSON.stringify({
          type: 'EVENT',
          message: 'conversation started'
        }),
        '*'
      )
    setTimeout(() => heyGenRef.current?.say(text), 2000)
  }

  const checkMediaPermissions = async () => {
    if (isTest) {
      setPermissionsError(null)
      setPermissionsChecked(true)
      setControlPanelVisible(true)
      return
    }
    try {
      jsLogger.debug('permissions_requested')
      setCheckingPermissions(true)
      await requestMediaPermissions()
      jsLogger.debug('permissions_granted')
      setCheckingPermissions(false)
      jsLogger.debug('no error with permissions')
      setPermissionsError(null)
      setPermissionsChecked(true)
      setControlPanelVisible(true)
      heyGenRef.current?.play()
      controlsRef.current = { mic: true, camera: true }
      setControls({ mic: true, camera: true })
    } catch (error: any) {
      jsLogger.error('MediaOnboardingDialog: ', error)
      if (error.type === MediaPermissionsErrorType.Generic) {
        setPermissionsError(Proto.PermissionErrorType.systemDenied)
      } else if (
        error.type === MediaPermissionsErrorType.SystemPermissionDenied
      ) {
        // user denied permission
        setPermissionsError(Proto.PermissionErrorType.systemDenied)
      } else if (
        error.type === MediaPermissionsErrorType.UserPermissionDenied
      ) {
        // browser doesn't have access to devices
        setPermissionsError(Proto.PermissionErrorType.userDenied)
      } else if (
        error.type === MediaPermissionsErrorType.CouldNotStartVideoSource
      ) {
        // most likely when other apps or tabs are using the cam/mic (mostly windows)
        setPermissionsError(Proto.PermissionErrorType.trackError)
      }
    }
  }

  const onAvatarPlayingFinished = (latency: number) => {
    jsLogger.log('%conAvatarPlayingFinished', { isOver: isOverRef.current })
    currentHGLatency.current = latency
    avatarIsTalkingRef.current = false
    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent(`ev_avatar_ended_talking_${steps.length}`, {
        latency,
        isOver: isOverRef.current,
        version: PACKAGE.version
      })
    }

    if (nextQuestionRef.current) {
      const q = nextQuestionRef.current
      jsLogger.log('found saved response', { q })
      sendApplyResponse(q)
      nextQuestionRef.current = null
      heyGenRef.current?.say(q.text)
      avatarIsTalkingRef.current = true
      // setIsRecording(true)
      // recorderRef.current?.start()
    } else if (isOverRef.current) {
      recorderRef.current?.stop()
      setTimeout(
        onConversationFinished,
        sessionInfo.avatar === 'azure' ? 2000 : 0
      )
    } else if (!userIsTalkingRef.current) {
      console.debug('avatar ended speaking and the user is not speaking')
      onUserPhrase('', 0)
    }
  }

  const sendApplyResponse = async (q: Phrase) => {
    console.log('sendApplyResponse start', { q })
    const r = await applyResponse(clientSecret, q)
    console.log('sendApplyResponse end', { q, steps: r && r.steps })
    if (r && r.steps) {
      setSteps(r.steps)
    }
  }

  const onUserPhrase = async (speech: string, dgLatency: number) => {
    userIsTalkingRef.current = false
    jsLogger.debug('onUserPhrase', {
      speech,
      avatarIsTalking: avatarIsTalkingRef.current
    })
    if (avatarIsTalkingRef.current) {
      jsLogger.debug(
        'avatar is currently talking, saving user speech to buffer'
      )
      userSpeechBufferRef.current = userSpeechBufferRef.current + speech
    } else {
      jsLogger.log('onUserPhrase', { buffer: userSpeechBufferRef.current })
      const speechWithBuffer = `${userSpeechBufferRef.current} ${speech}`
      jsLogger.log('speechWithBuffer:', { speech: speechWithBuffer })
      try {
        if (trim(speechWithBuffer) === '') {
          jsLogger.debug(
            'ignore the user phrase because speechWithBuffer is empty',
            {
              speechWithBuffer
            }
          )
          return null
        }
        userSpeechBufferRef.current = ''
        const res = await submitReply(clientSecret, speechWithBuffer)
        jsLogger.log('submit reply res', res)
        if (res && has(res, 'error')) {
          const er = res as Proto.Error
          jsLogger.error(er)
        } else {
          const apires = res as Proto.SubmitReplyResponse
          const q = apires.phrase
          jsLogger.debug('next question is', { text: q.text })
          const over = apires.isOver
          jsLogger.log('res is over', { over })
          setSteps(apires.steps)

          // setSteps([
          //   ...stepsRef.current,
          //   generateStep(res.phrase, InteractionT.StepTypeT.QUESTION)
          // ])
          // currentQuestionId.current = res.questionId
          if (isOverRef.current) {
            jsLogger.debug(
              'skipping the avatar reply, because the conversation is already over'
            )
          } else if (avatarIsTalkingRef.current) {
            jsLogger.debug('avatar is currently talking')
            // if (over) {
            if (!isOverRef.current && over) {
              jsLogger.debug(
                'isOver flag received, saving the avatar phrase to the buffer'
              )
              nextQuestionRef.current = q
            }
          } else if (userIsTalkingRef.current) {
            jsLogger.debug(
              'user is currently talking, skiping the avatar phrase and wait for a new one'
            )
          } else if (trim(q.text) === '') {
            jsLogger.warn('WARNING: backend returned an empty phrase')
          } else if (trim(q.text) !== '') {
            jsLogger.debug('avatar is NOT talking, switching to talking')
            sendApplyResponse(q)
            nextQuestionRef.current = null
            heyGenRef.current?.say(q.text)
            avatarIsTalkingRef.current = true
          }

          // if (res.userName && isNil(userName)) {
          //   setUserName(res.userName)
          // }
          if (over) {
            isOverRef.current = over
          }
        }
      } catch (e) {
        jsLogger.error(e)
      }
    }
  }

  const handleControlsChange = (v: Proto.ControlT) => {
    jsLogger.debug('handleControlsChange', v)
    if (!permissionsChecked) {
      checkMediaPermissions()
    }
    controlsRef.current = v
    setControls(v)
  }

  const onScreenshot = async (b: Blob) => {
    // if (interactionId && !isTest) {
    //   if (screenshotsSentRef.current > SCREENSHOTS_AMOUNT) {
    //     console.log(
    //       `${screenshotsSentRef.current} is already sent, skipping this one`
    //     )
    //   }
    //   const sent = await sendUserScreenshot(interactionId, b)
    //   if (sent) {
    //     screenshotsSentRef.current = screenshotsSentRef.current + 1
    //     if (screenshotsSentRef.current >= SCREENSHOTS_AMOUNT) {
    //       jsLogger.debug(`${screenshotsSentRef.current} screenshots sent`)
    //       window.clearInterval(screenshotTimer.current)
    //     }
    //   }
    // }
  }

  const takeScreenshot = async () => {
    jsLogger.log('takeScreenshot call start', {
      onScreenshotFunctionExists: !isNil(onScreenshot),
      cameraRefExists: !isNil(cameraVideoRef.current)
    })
    if (cameraVideoRef.current && onScreenshot) {
      jsLogger.log('camera video ref and onScreenshot function exist')
      const screenshotBlob = await captureThumb(cameraVideoRef.current)
      if (screenshotBlob) {
        jsLogger.log('screenshot blob created')
        // logEvent(interactionId, 'screenshot_captured')
        onScreenshot(screenshotBlob)
      } else {
        // logEvent(interactionId, 'screenshot_capture_error', {
        //   reason: 'no screenshotBlob'
        // })
      }
    } else {
      jsLogger.warn(
        'camera is not initialized or onScreenshot function is not passed'
      )
      // logEvent(interactionId, 'screenshot_capture_error', {
      //   reason: 'camera is not initialized'
      // })
    }
  }

  const onSpeech = (v: string) => {
    jsLogger.log('Conversation onSpeech', {
      speech: v,
      buffer: userSpeechBufferRef.current
    })
    // FIXME: pass current trnscript to controlPanel
    controlPanelRef.current?.setSpeech(`${userSpeechBufferRef.current} ${v}`)
    jsLogger.log('onSpeech', {
      avatarIsTalking: avatarIsTalkingRef.current,
      isOver: isOverRef.current
    })
    if (avatarIsTalkingRef.current && !isOverRef.current) {
      heyGenRef.current?.cancel()
    }
  }

  const handleClose = () => {
    setClosed(true)
    const back = getBackClient()
    back.disconnect()
  }

  const handleVideoClick = () => {
    if (orientation === 'portrait' && !permissionsError && permissionsChecked) {
      controlPanelRef?.current?.click()
    }
  }

  const handleCameraChange = (v: string | null) => {
    setSelectedDeviceId(v)
    recorderRef.current?.cameraChange(v)
  }

  if (closed) {
    return <EndConversation />
  }

  if (permissionsError) {
    return <PermissionsHandler permissionsError={permissionsError} />
  }

  const avatarEnabled = true

  const renderAvatar = () => {
    if (sessionInfo.azureKey) {
      return (
        <AzureConnect
          ref={heyGenRef}
          onAvatarPlayingFinished={onAvatarPlayingFinished}
          thereWasAnError={thereWasAnError}
          setThereWasAnError={setThereWasAnError}
          onSessionStarted={onHeyGenSessionStarted}
          isRecording={false}
          permissionsGranted={permissionsChecked}
          // setDuration={setDuration}
          // handleChunk={isTest ? undefined : handleChunk}
          setDuration={() => null}
          handleVideoClick={handleVideoClick}
          azureKey={sessionInfo.azureKey}
        />
      )
    } else if (sessionInfo.heygenKey) {
      return (
        <HeyGenConnect
          ref={heyGenRef}
          onAvatarPlayingFinished={onAvatarPlayingFinished}
          thereWasAnError={thereWasAnError}
          setThereWasAnError={setThereWasAnError}
          onSessionStarted={onHeyGenSessionStarted}
          isRecording={false}
          permissionsGranted={permissionsChecked}
          // handleChunk={isTest ? undefined : handleChunk}
          // setDuration={setDuration}
          setDuration={() => null}
          handleVideoClick={handleVideoClick}
          heygenKey={sessionInfo.heygenKey}
        />
      )
    }
  }

  return (
    <PageContainer version={PACKAGE.version}>
      {avatarEnabled && renderAvatar()}
      {isTest && (
        <RecorderDummy
          ref={recorderRef}
          onPhrase={onUserPhrase}
          isActive={false}
          onSpeech={onSpeech}
          cameraVideoRef={cameraVideoRef}
          controls={controls}
          setControls={handleControlsChange}
          setThereWasAnError={setThereWasAnError}
          permissionsError={permissionsError}
          streamRef={streamRef}
        />
      )}
      {controlPanelVisible && !isTest && (
        <div
          className={`flex-1 bg-slate-100 rounded-md absolute top-[3rem] left-5 w-28 h-44`}
        >
          <Recorder
            ref={recorderRef}
            // handleChunk={handleChunk}
            // handleChunk={isTest ? undefined : handleChunk}
            onPhrase={onUserPhrase}
            isActive={true}
            onSpeech={onSpeech}
            cameraVideoRef={cameraVideoRef}
            controls={controls}
            setControls={handleControlsChange}
            setThereWasAnError={setThereWasAnError}
            permissionsError={permissionsError}
            streamRef={streamRef}
            interactionId={clientSecret}
            dgKey={sessionInfo.dgKey}
            setDevices={setDevices}
            setSelectedDeviceId={setSelectedDeviceId}
            onStartTalking={() => (userIsTalkingRef.current = true)}
          />
        </div>
      )}

      {permissionsError === Proto.PermissionErrorType.userDenied && (
        <FixPermission />
      )}
      {controlPanelVisible && (
        <ControlPanel
          ref={controlPanelRef}
          permissionsError={permissionsError}
          controls={controls}
          setControls={handleControlsChange}
          handleClose={handleClose}
          permissionsChecked={permissionsChecked}
          // steps={steps}
          steps={[]}
          userName={userName}
          isRecording={true}
          orientation={orientation}
          devices={devices}
          selectedDeviceId={selectedDeviceId}
          setSelectedDeviceId={handleCameraChange}
        />
      )}
      {!controlPanelVisible && !permissionsChecked && (
        <div className='absolute top-0 left-0 bottom-0 right-0 w-full h-full flex items-center justify-center'>
          {/* {!checkingPermissions && (
            <>
              <video
                src='avatar_initial.mp4'
                className='w-full h-full object-cover absolute'
                poster='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/avatar_initial_bg.png?alt=media&token=3653cda3-365d-4ddc-9e4b-2160bd949f7b'
                muted
                autoPlay
                loop
                playsInline
                ref={introVideoRef}
              />
              <BackgroundRemover videoRef={introVideoRef} />
            </>
          )} */}
          <div className='z-10 absolute top-20 left-4 text-gray-300'>
            <div>
              <div style={{ height: '50px', width: '50px' }}>
                <DotLottiePlayer
                  src='https://lottie.host/1ba91606-15d0-4f27-9f17-095c58dfb5ac/IivUjwcaOY.json'
                  autoplay
                  loop
                  background='transparent'
                  speed={1}
                  style={{ width: '100%', height: '100%' }}
                  direction={1}
                />
              </div>
            </div>
            <span className='text-[10px] pt-1'>
              <i>Turn your volume up</i>
            </span>
          </div>
          {checkingPermissions && (
            <div className='z-10 text-[24px] text-white font-semibold flex flex-col items-center'>
              <span className='drop-shadow-[1px_1px_1px_rgba(0,0,0,1)]'>
                <i>Initializing</i>
              </span>
              <div className='h-1 w-[187px] bg-white mt-[23px]'>
                <div className='h-1 w-[106px] bg-teal-600' />
              </div>
            </div>
          )}

          {!checkingPermissions && (
            <div className='z-10 w-[300px] flex flex-col items-center'>
              <span className='font-semibold text-[24px] text-white drop-shadow-[1px_1px_1px_rgba(0,0,0,1)] text-center leading-8'>
                You're about to chat
                <br />
                with a digital assistant.
              </span>
              <div className='w-full bg-white mt-10 rounded-xl flex flex-col items-center pt-7 pb-4'>
                <span className='text-[20px] font-semibold text-center leading-7'>
                  Allow FaceSign to use your
                  <br />
                  camera and microphone?
                </span>
                <button
                  className='h-16 bg-blue-400 text-white font-medium text-[16px] w-[260px] mt-7 rounded-[4px]'
                  onClick={() =>
                    handleControlsChange({ mic: true, camera: true })
                  }
                >
                  Allow
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </PageContainer>
  )
}

export default Conversation
