import * as Sentry from '@sentry/react'
import get from 'lodash/get'
import PACKAGE from '../../package.json'
import jsLogger from 'js-logger'
import * as Proto from 'shared/types/proto'
import { Phrase } from '@facesignai/api'

const getParentUrl = () => {
  const isInIframe = window.parent !== window
  let parentUrl = ''

  if (isInIframe) {
    parentUrl = document.referrer
  }

  return parentUrl
}

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Parent-Href': getParentUrl()
}

// const mimeToExtMap = {
//   'video/mp4': 'mp4',
//   'video/webm': 'webm',
//   'video/ogg': 'ogg',
//   'video/quicktime': 'mov',
//   'video/x-matroska': 'mkv',
//   'video/x-msvideo': 'avi'
// }

export const conversationStart = async (clientSecret: string) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/fsclient/start`
    const params: Proto.SessionStartRequest = {
      version: PACKAGE.version
    }
    const req = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: `Bearer ${clientSecret}`
      },
      body: JSON.stringify(params)
    })
    jsLogger.log('conversationStart call start', { url, headers })
    const res = (await req.json()) as Proto.SessionStartResponse | Proto.Error
    jsLogger.log('conversationStart call res', res)
    return res
  } catch (e) {
    jsLogger.error('interactionStart error', e)
    // Sentry.captureException(e)
    return { error: get(e, 'message', 'unknown') } as Proto.Error
  }
}

export const submitReply = async (clientSecret: string, reply: string) => {
  try {
    // const pageUrl = new URL(window.location.href)
    const url = `${process.env.REACT_APP_API_URL}/fsclient/submit_reply`
    const params: Proto.SubmitReplyRequest = {
      reply
    }
    const startTime = Date.now()
    const req = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: `Bearer ${clientSecret}`
      },
      body: JSON.stringify(params)
    })
    const res = await req.json()
    jsLogger.log('create reply latency', Date.now() - startTime)
    jsLogger.log('submitReply res', res)
    return res as Proto.SubmitReplyResponse | Proto.Error
  } catch (e) {
    jsLogger.warn('submitReply error', e)
    // Sentry.captureException(e)
    return { error: get(e, 'message', 'unknown') } as Proto.Error
  }
}

export const applyResponse = async (clientSecret: string, q: Phrase) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/fsclient/apply_response`
    const params = {
      q
    }
    const req = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: `Bearer ${clientSecret}`
      },
      body: JSON.stringify(params)
    })
    const res = await req.json()
    jsLogger.log('applyResponse res', res)
    return res
  } catch (e) {
    jsLogger.warn('applyResponse error', e)
    // Sentry.captureException(e)
    return
  }
}

export const sendUserScreenshot = async (
  interactionId: string,
  screenshotBlob: Blob
) => {
  try {
    jsLogger.log('sendUserScreenshot', screenshotBlob.size)
    const url = `${process.env.REACT_APP_API_URL}/emailCode/${interactionId}/screenshot`
    const req = await fetch(url, {
      method: 'POST',
      body: screenshotBlob,
      headers: { ...headers, 'Content-Type': 'application/octet-stream' }
    })
    const data = await req.json()

    jsLogger.log('sendUserScreenshot response', data)
    return data.success
  } catch (e) {
    jsLogger.error('could not sent screenshot', { error: e })
    return false
  }
}

export const generateId = (): string => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')
  const hours = currentDate.getHours().toString().padStart(2, '0')
  const minutes = currentDate.getMinutes().toString().padStart(2, '0')
  const seconds = currentDate.getSeconds().toString().padStart(2, '0')
  const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0')
  const id = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`
  return id
}

export const sendError = async (
  event: Sentry.Event,
  hint: Sentry.EventHint
) => {
  const url = `${process.env.REACT_APP_API_URL}/emailCode/error`
  const req = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ event, hint }),
    headers
  })
  const data = await req.json()

  jsLogger.log('sendUserScreenshot response', data)
  return data
}

export const checkIsWebMSupported = () => {
  // Create a dummy video element
  var video = document.createElement('video')

  // Check if the browser supports the WebM video format
  return video.canPlayType('video/webm') !== ''
}
