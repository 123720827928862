import { useEffect, useState } from 'react'
import jsLogger from 'js-logger'
// import * as Sentry from '@sentry/react'
import has from 'lodash/has'
import { conversationStart } from 'controllers/main'
import Conversation from 'pages/Conversation'
import * as Proto from 'shared/types/proto'
import ErrorPage from 'pages/ErrorPage'
import FinalPage from 'pages/FinalPage'

const App = () => {
  // const [initializing, setInitializing] = useState(true)
  const [error, setError] = useState<string>()
  const [sessionInfo, setSessionInfo] = useState<Proto.SessionStartResponse>()
  const [isComplete, setIsComplete] = useState(false)
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  // const [recordStopped, setRecordStopped] = useState(false)
  // const chunksRef = useRef<{ user: Blob[]; avatar: Blob[] }>({
  //   user: [],
  //   avatar: []
  // })
  // const [videoSavingDisabled, setVideoSavingDisabled] = useState(true)
  // const backClientRef = useRef<BackClient>(getBackClient())

  useEffect(() => {
    const url = new URL(window.location.href)
    const cs = url.searchParams.get('cs')
    setClientSecret(cs)

    jsLogger.debug('session start', { url, scExists: cs !== null })

    const run = async () => {
      if (cs) {
        jsLogger.debug('conversationStart api call start')
        const apires: Proto.SessionStartResponse | Proto.Error =
          await conversationStart(cs)
        jsLogger.debug('conversationStart api call end', { apires })
        if (apires && has(apires, 'error')) {
          const er = apires as Proto.Error
          setError(er.error)
          window.top &&
            window.top.postMessage(
              JSON.stringify({ type: 'ERROR', message: er.error }),
              '*'
            )
        } else {
          const sInfo = apires as Proto.SessionStartResponse
          setSessionInfo(sInfo)
        }
        // if (apires) {
        //   if (apires.interactionId) {
        //     backClientRef.current.interactionStarted(
        //       apires.interactionId,
        //       noVideo
        //     )
        //     jsLogger.log('conversationStart interactionId', {
        //       interactionId: apires.interactionId
        //     })
        //     setInteractionId(apires.interactionId)
        //     Sentry.setContext('session', {
        //       verificationId: id,
        //       interactionId: apires.interactionId
        //     })
        //   }
        //   apires.steps && setSteps(apires.steps)
        //   apires.dgKey && apires.dgKey.key && setDgKey(apires.dgKey.key)
        //   apires.azureKey && setAzureKey(apires.azureKey)
        // }
      }
      // setInitializing(false)
    }
    run()
  }, [])

  const onConversationFinished = () => {
    if (sessionInfo) {
      jsLogger.debug('conversation finished', {
        sessionInfo
      })
    }
    setIsComplete(true)
    window.top &&
      window.top.postMessage(
        JSON.stringify({ type: 'EVENT', message: 'SESSION_FINISHED' }),
        '*'
      )
    // setRecordStopped(true)
  }

  // const handleChunk = async (
  //   chunk: Blob,
  //   mimeType: string,
  //   role: 'user' | 'avatar'
  // ) => {
  //   chunksRef.current[role].push(chunk)
  //   if (!videoSavingDisabled) {
  //     backClientRef.current.sendVideoChunk(chunk, mimeType, role)
  //   }
  //   // chunksRef.current[role].push(chunk)
  //   // if (interactionId) {
  //   //   const storagePath = interactionId
  //   //   try {
  //   //     const concatenatedBlob = new Blob(chunksRef.current[role], {
  //   //       type: mimeType
  //   //     })
  //   //     chunksRef.current[role] = []
  //   //     const id = generateId()
  //   //     setUploadQueue(v => {
  //   //       v.push(id)
  //   //       return v
  //   //     })
  //   //     await uploadBlobToStorage(
  //   //       concatenatedBlob,
  //   //       `${storagePath}/${role}_chunks/${id}`
  //   //     )
  //   //     setUploadQueue(v => {
  //   //       return without(v, id)
  //   //     })
  //   //   } catch (error) {
  //   //     jsLogger.error('Error uploading chunks:', error)
  //   //   }
  //   // }
  // }

  const renderContent = () => {
    if ((!clientSecret || !sessionInfo) && !error) {
      return null
      // } else if (recordStopped && !isComplete) {
      //   return <Loading waitingForUpload />
    } else if (isComplete) {
      return <FinalPage />
    } else if (error) {
      return <ErrorPage error={error} />
    } else if (sessionInfo && clientSecret) {
      return (
        <Conversation
          clientSecret={clientSecret}
          sessionInfo={sessionInfo}
          onConversationFinished={onConversationFinished}
        />
      )
    }
  }

  return (
    <div className='w-full h-full relative flex justify-center items-center no-scrollbar'>
      {renderContent()}
    </div>
  )
}

export default App
