import { FaceDetail, FaceDetection } from './amazon'
export namespace API {
  export type BrandingT = {
    url: string
    fit?: 'cover' | 'logo'
  }

  export type ButtonT = {
    label: string
    variant?: 'solid' | 'outline'
    color?: string | null
  }

  export type LandingT = {
    branding?: BrandingT | null
    primaryHeading: string
    locationSubheading?: string | null
    ctaText: string
    termsAndConditionsUrl?: string | null
    actionButton: ButtonT
    actionButton2: ButtonT
  }

  export type CreateLinkT = {
    landing?: LandingT
  }
}

export namespace InteractionT {
  export enum StepTypeT {
    QUESTION = 'question',
    LANDING = 'landing',
    REPLY = 'reply'
  }

  export type BrandingT = {
    url: string
    fit: 'cover' | 'logo'
  }

  export type ButtonT = {
    label: string
    variant?: 'solid' | 'outline' | null
    color?: string | null
  }

  export type LandingT = {
    id: 'landing'
    branding?: BrandingT
    primaryHeading: string
    locationSubheading?: string
    ctaText: string
    termsAndConditionsUrl?: string
    actionButton: ButtonT
    actionButton2: ButtonT
    buttonPressed?: 0 | 1
    sType: StepTypeT.LANDING
  }

  export interface QuestionT {
    id: string
    createdAt: number
    length?: number
    thumbnail?: string
    mediaUrl?: string
    text: string
    answerText?: string
    answerId?: string
    sType: StepTypeT.QUESTION
    aiLatency?: number
    heyGenLatency?: number
    isApplied?: boolean
  }

  export interface ReplyT {
    id: string
    text: string
    createdAt: number
    mediaUrl?: string
    sType: StepTypeT.REPLY
    name?: string
    deepgramLatency?: number
  }

  export type StepT = QuestionT | LandingT | ReplyT

  export type RekognitionT = {
    startTime: number
    endTime: number
    faces: FaceDetection[]
  }

  export type ImageRekognitionT = {
    startTime: number
    endTime: number
    faces: FaceDetail[]
  }

  export type IpDetailsT = {
    ip?: string
    success?: boolean
    type?: string
    continent?: string
    continent_code?: string
    country?: string
    country_code?: string
    region?: string
    region_code?: string
    city?: string
    latitude?: number
    longitude?: number
    is_eu?: boolean
    postal?: string
    calling_code?: string
    capital?: string
    borders?: string
    flag?: {
      img?: string
      emoji?: string
      emoji_unicode?: string
    }
    connection?: {
      asn?: number
      org?: string
      isp?: string
      domain?: string
    }
    timezone?: {
      id?: string
      abbr?: string
      is_dst?: boolean
      offset?: number
      utc?: string
      current_time?: string
    }
    currency?: {
      name?: string
      code?: string
      symbol?: string
      plural?: string
      exchange_rate?: number
    }
    security?: {
      anonymous?: boolean
      proxy?: boolean
      vpn?: boolean
      tor?: boolean
      hosting?: boolean
    }
  }

  export interface DeviceDetailsT {
    isMobile: boolean
    isMobileNative: boolean
    isTablet: boolean
    isiPad: boolean
    isiPod: boolean
    isiPhone: boolean
    isAndroid: boolean
    isBlackberry: boolean
    isOpera: boolean
    isIE: boolean
    isEdge: boolean
    isIECompatibilityMode: boolean
    isSafari: boolean
    isFirefox: boolean
    isWebkit: boolean
    isChrome: boolean
    isKonqueror: boolean
    isOmniWeb: boolean
    isSeaMonkey: boolean
    isFlock: boolean
    isAmaya: boolean
    isEpiphany: boolean
    isDesktop: boolean
    isWindows: boolean
    isWindowsPhone: boolean
    isLinux: boolean
    isLinux64: boolean
    isMac: boolean
    isChromeOS: boolean
    isBada: boolean
    isSamsung: boolean
    isRaspberry: boolean
    isBot: boolean
    isCurl: boolean
    isAndroidTablet: boolean
    isWinJs: boolean
    isKindleFire: boolean
    isSilk: boolean
    isCaptive: boolean
    isSmartTV: boolean
    silkAccelerated: boolean
    browser: string
    version: string
    os: string
    platform: string
    geoIp: { [key: string]: any }
    source: string
  }

  export type VariantT = 'LlmSystem' | 'OpenAI'
  export type AvatarT = 'heygen' | 'azure' | 'custom'

  export type T = {
    id: string
    createdAt: number
    linkId: string
    steps: StepT[]
    completed: boolean
    rekognition?: Record<string, RekognitionT>
    imageRekognition?: ImageRekognitionT
    deviceDetails?: DeviceDetailsT
    ipDetails?: IpDetailsT
    userId?: string
    isNotNew?: boolean
    slackTs?: string
    slackNotificationsDisabled?: boolean
    variant?: VariantT
    transcriptSlackTs?: string
    logsSlackTs?: string
    avatar?: AvatarT
  }

  export type LogTypeT = string
  export type LogLocationT = 'frontend' | 'transcriber'

  export type LogT = {
    timestamp?: number
    type: LogTypeT
    location: LogLocationT
    data?: object
  }

  export type AIAnalysisSection = {
    title: string
    shortDescription: string
    longDescription: string
  }

  export type AIAnalysis = {
    bestPhotoIndex: number
    ageMin: number
    ageMax: number
    sex: 'male' | 'female'
    realPersonOrVirtual: 'real' | 'virtual'
    overallSummary: string
    analysis: AIAnalysisSection[]
  }

  export type EmailCodeT = Omit<T, 'linkId' | 'imageRekognition'> & {
    verificationId: string
    isVerified?: boolean
    userName?: string
    userVideoUrl?: string
    avatarVideoUrl?: string
    userVideoMimeType?: string
    avatarVideoMimeType?: string
    imageRekognition?: ImageRekognitionT[]
    userAvatarUrl?: string
    userScreenshots?: string[]
    chunksCompleted?: boolean
    webmDuration?: number
    isTest?: boolean
    version?: string
    model?: string
    isDev?: boolean
    firstName: null | string
    lastName: null | string
    recapVideoUrl?: string
    aiAnalysis?: AIAnalysis
  }

  export type EmailAttemptT = {
    ts: string
    timestamp: number
    deliveredAt?: number
  }

  export type VerificationT = {
    id: string
    code: string
    email: string
    createdAt: number
    agreed: boolean
    slackNotifications?: Record<string, EmailAttemptT>
    isTest?: boolean
  }

  export type RecapT = EmailCodeT
}

export namespace LinkT {
  export type T = {
    id: string
    createdAt: number
    landing?: InteractionT.LandingT
    modifiedAt?: number
  }
}

export type ControlT = {
  camera: boolean
  mic: boolean
}

export enum PermissionErrorType {
  explanation = 'explanation',
  systemDenied = 'systemDenied',
  userDenied = 'userDenied',
  trackError = 'trackError'
}
